import React from 'react'

import { Button } from 'components/ui'
import { IconOptions } from 'components/ui/Icon'
import { RichTextButtonStoryblok } from 'lib/storyblok/types'
import { getAnchorFromCmsLink, hasValidLink } from 'lib/utils/content'

type Props = {
  block: RichTextButtonStoryblok
}

export const RichTextButton = ({ block }: Props): JSX.Element | null => {
  const { link, label, right_icon, left_icon } = block

  if (!link || !hasValidLink(link)) {
    return null
  }

  const { href, rel, target } = getAnchorFromCmsLink(link)

  return (
    <div className="flex md:max-w-[658px] md:mx-auto w-full">
      <div className="flex">
        <Button
          as="a"
          href={href}
          rel={rel}
          target={target}
          icon={(left_icon as IconOptions) || (right_icon as IconOptions)}
          iconPosition={left_icon ? 'left' : 'right'}
          variant="outline"
        >
          {label}
        </Button>
      </div>
    </div>
  )
}
